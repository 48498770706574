import ready from "./ready";

let stripe;

const isReady = async () => {
  return !!stripe;
}
const setKey = async (key) => {
  return ready(() => {
    stripe = new Stripe(key);
  });
}
const createElement = async (type, elementId, { options = {}, elementOptions = {} }) => {
  return ready(() => {
    const elements = stripe.elements(options);
    const element = elements.getElement(type) || elements.create(type, elementOptions);
    element.mount(`#${elementId}`);
    return { element, elements };
  });
}
const confirmPayment = async (options = {}) => {
  return ready(() => {
    return stripe.confirmPayment(options);
  });
}
const confirmCardSetup = async (secret, options = {}) => {
  return ready(() => {
    return stripe.confirmCardSetup(secret, options);
  });
}
const confirmSepaDebitSetup = async (secret, options = {}) => {
  return ready(() => {
    return stripe.confirmSepaDebitSetup(secret, options);
  });
}
export default {
  isReady, setKey, createElement, confirmPayment, confirmCardSetup, confirmSepaDebitSetup
}
